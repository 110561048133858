/** @jsx jsx */
import React from "react";
import { jsx, Themed } from "theme-ui";
import { useEffect, useState, useMemo } from "react";
import { Link } from "gatsby";
import { addLike, fetchActivity } from "api";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import Layout from "../components/Layout";
import Grid from "../components/Grid";
import RichText from "../components/RichText";
import Separator from "../components/Separator";
import PrintButton from "../components/PrintButton";
import DownloadWordButton from "../components/DownloadWordButton";
import EmailButton from "../components/EmailButton";
import CopyURLButton from "../components/CopyURLButton";
import ActivityGroupCardGrid from "../components/ActivityGroupCardGrid";
import QuickActivityFinder from "../components/QuickActivityFinder";
import Lightbox from "../components/Lightbox";
import FacebookButton from "components/FacebookButton";
import PinterestButton from "components/PinterestButton";
import Pill from "components/Pill";
import CommentsSection from "components/CommentsSection";
import ColorMap from "../utils/ColorMap";
import { useIsTablet } from "../hooks/ThemeHooks";

import TimeIcon from "../assets/icons/time.svg";
import OneParticipantIcon from "../assets/icons/participants_one.svg";
import MultipleParticipantsIcon from "../assets/icons/participants_multiple.svg";
import likeIcon from "assets/icons/like.svg";
import likeIconWhite from "assets/icons/like_white.svg";
import likeIconRed from "assets/icons/like_red.svg";

import CreativeIcon from "../assets/icons/creative.svg";
import PhysicalIcon from "../assets/icons/physical.svg";
import SocialIcon from "../assets/icons/social.svg";
import CognitiveIcon from "../assets/icons/cognitive.svg";
import EmotionalIcon from "../assets/icons/emotional.svg";

import { HaveFun } from "../assets/images/brailleheaders";

const HolisticSkillIconMap = {
  creative: CreativeIcon,
  physical: PhysicalIcon,
  social: SocialIcon,
  cognitive: CognitiveIcon,
  emotional: EmotionalIcon,
};

const Activity = ({
  pageContext: { activity, activityGroups, allActivities },
  location,
}) => {
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();
  const [likes, setLikes] = useState(activity.likes);
  const [didLikePost, setDidLikePost] = useState(false);
  const [announceLike, setAnnounceLike] = useState(false);
  const [comments, setComments] = useState(activity.comments);
  const [didAddComment, setDidAddComment] = useState(false);
  const [lightboxImageIndex, setLightboxImageIndex] = useState(null);
  const isTablet = useIsTablet();
  console.log({ activity, likes });

  const secondColumn = useMemo(
    () => (
      <div
        sx={{
          flex: 1,
          marginTop: [null, "3rem", 0],
        }}
      >
        <Themed.h3
          sx={{
            variant: "text.h6",
            fontWeight: "bold",
            marginBottom: "40px",
            "@media print": {
              marginBottom: "1rem",
            },
          }}
        >
          Children will develop these holistic skills
        </Themed.h3>
        <Themed.div
          sx={{
            display: "flex",
            flexDirection: ["column", "row", "column"],
            justifyContent: "space-between",
            flexWrap: ["wrap", "wrap", "nowrap"],
          }}
        >
          {activity.goalsBySkill.map(({ holisticSkill, goals }, index) => (
            <div
              key={holisticSkill.uid}
              sx={{
                boxSizing: "border-box",
                flexBasis: "50%",
                paddingRight: [null, index % 2 === 0 ? "2rem" : 0, 0],
                marginBottom: "2rem",
                "@media print": {
                  paddingRight: "0",
                  marginBottom: "0.5rem",
                },
              }}
            >
              <Link
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  border: "1px solid",
                  borderColor: "gray",
                  width: "100%",
                  height: "3rem",
                  textDecoration: "none",
                  color: "text",
                  "@media print": {
                    border: "none",
                    height: "1rem",
                  },
                }}
                to={`/learningthroughplay/${holisticSkill.uid}`}
              >
                <div
                  sx={{
                    background: `url(${
                      HolisticSkillIconMap[holisticSkill.uid]
                    }) no-repeat 50% 50%`,
                    bg: ColorMap[holisticSkill.uid],
                    width: "3rem",
                    height: "100%",
                    "@media print": {
                      display: "none",
                    },
                  }}
                ></div>
                <Themed.h4
                  sx={{
                    variant: "text.p",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    padding: "0 1rem",
                    "@media print": {
                      padding: "0",
                      display: "inline-block",
                      fontStyle: "italic",
                    },
                  }}
                >
                  <span
                    sx={{ "::first-letter": { textTransform: "capitalize" } }}
                  >
                    {`${holisticSkill.uid} skills`}
                  </span>
                </Themed.h4>
              </Link>
              <ul>
                {goals.map(goal => (
                  <Themed.li key={goal.uid}>
                    {goal.parent && `${goal.parent.text}: `}
                    {goal.text}
                  </Themed.li>
                ))}
              </ul>
            </div>
          ))}
        </Themed.div>
        <Themed.div sx={{ marginBottom: "3rem" }}>
          <Themed.h3
            sx={{
              variant: "text.h6",
              fontWeight: "bold",
            }}
          >
            Did you know?
          </Themed.h3>
          <RichText render={activity.didYouKnow} />
        </Themed.div>
      </div>
    ),
    [activity.didYouKnow, activity.goalsBySkill]
  );

  const pageTitle =
    lightboxImageIndex !== null
      ? `Enlarged image of step ${lightboxImageIndex + 1} in ${activity.title}`
      : activity.seo.title || activity.title;

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await fetchActivity({ id: activity.id });
        setLikes(data.activity.likes ?? 0);
        setComments(data.activity.commentsCollection.items);
      } catch (e) {
        console.error(e);
      }
    }
    fetchData();
  }, [activity.id]);

  useEffect(() => {
    if (didAddComment) {
      const { id } = comments[comments.length - 1].sys;
      const element = document.getElementById(id);
      element.scrollIntoView({ behavior: "smooth", block: "center" });
      setDidAddComment(false);
    }
  }, [didAddComment]);

  async function handleLikePost() {
    try {
      setDidLikePost(true);
      setLikes(state => (state += 1));
      setAnnounceLike(true);
      localStorage.setItem(activity.id, "true");
      await addLike({ id: activity.id });
    } catch (e) {
      console.error(e);
    }
  }

  function handleAddComment(comment) {
    setComments(comments => [...comments, comment]);
    setDidAddComment(true);
  }

  async function goToLogin() {
    try {
      await instance.loginRedirect({});
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <Layout
      title={pageTitle}
      description={activity.seo.description}
      breadcrumbsMap={{
        [activity.activityGroup.uid]: activity.activityGroup.name,
        [activity.uid]: activity.title,
      }}
      brailleTextImage={HaveFun}
    >
      <Themed.h1 sx={{ variant: "text.h2" }}>{activity.title}</Themed.h1>
      <div
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          marginBottom: "40px",
          "@media print": {
            marginBottom: "20px",
          },
        }}
      >
        <img
          src={TimeIcon}
          sx={{
            width: "24px",
            height: "24px",
            objectFit: "contain",
            marginRight: "0.5rem",
          }}
          alt=""
        />
        <span
          aria-label={`${activity.time} minutes`}
        >{`${activity.time} min`}</span>
        <img
          src={
            activity.numberOfParticipants === 1
              ? OneParticipantIcon
              : MultipleParticipantsIcon
          }
          sx={{
            width: "28px",
            height: "18px",
            objectFit: "contain",
            margin: "0 0.5rem",
          }}
          alt=""
        />
        {`${activity.numberOfParticipants} ${
          activity.numberOfParticipants === 1 ? "participant" : "participants"
        }`}
      </div>
      <Themed.p
        sx={{
          display: "none",
          "@media print": {
            display: "block",
            marginBottom: "20px",
          },
        }}
      >
        {activity.summary}
      </Themed.p>
      <div
        sx={{
          display: "flex",
          flexDirection: ["column", null, "row"],
        }}
      >
        <div sx={{ flex: 2, paddingRight: "4rem" }}>
          <Grid
            columns={[2, 3, 4]}
            style={{ marginBottom: "40px" }}
            variant="print"
          >
            {activity.images.map((image, index) => (
              <button
                key={image.url}
                sx={{
                  boxSizing: "border-box",
                  padding: 0,
                  border: "none",
                  background: "transparent",
                  cursor: "pointer",
                }}
                onClick={() => setLightboxImageIndex(index)}
                aria-label={`Enlarge image of step ${
                  index + 1
                }, described in text below`}
              >
                <img
                  src={image.url}
                  alt={image.alt}
                  sx={{
                    width: "100%",
                    "@media print": {
                      maxHeight: "3cm",
                      width: "auto",
                    },
                  }}
                  object-fit="cover"
                />
              </button>
            ))}
          </Grid>
          <Themed.div sx={{ maxWidth: "700px" }}>
            <Themed.div
              sx={{
                marginBottom: "3rem",
                padding: "1.5rem",
                border: "1px solid gray",
                boxShadow: "0px 6px 11px -4px rgba(0, 0, 0, 0.3)",
                "@media only screen and (max-width: 600px)": {
                  border: "none",
                  borderBottom: "2px solid #777777",
                  borderTop: "2px solid #777777",
                  boxShadow: "none",
                  padding: "2rem 0",
                },
                "@media print": {
                  boxShadow: "none",
                  padding: "1rem",
                },
              }}
            >
              <Themed.h2 sx={{ variant: "text.h4", marginBottom: "20px" }}>
                Let's play!
              </Themed.h2>
              <RichText render={activity.howTo} />
            </Themed.div>
            <Themed.div sx={{ marginBottom: "3rem" }}>
              <Themed.h2 sx={{ variant: "text.h4", marginBottom: "20px" }}>
                How to prepare
              </Themed.h2>
              <RichText render={activity.equipment} />
              <RichText render={activity.preparation} />
            </Themed.div>
            <Themed.div sx={{ marginBottom: "3rem" }}>
              <Themed.h2 sx={{ variant: "text.h4", marginBottom: "20px" }}>
                Facilitation tips
              </Themed.h2>
              <RichText render={activity.tipsForFacilitation} />
            </Themed.div>
            <Themed.div sx={{ marginBottom: "3rem" }}>
              <Themed.h2 sx={{ variant: "text.h4", marginBottom: "20px" }}>
                Possible variations
              </Themed.h2>
              <RichText render={activity.possibleExtensions} />
            </Themed.div>
          </Themed.div>
          {isTablet && secondColumn}
          <div sx={{ "@media print": { display: "none" } }}>
            <Themed.h2 sx={{ variant: "text.h4", marginBottom: "30px" }}>
              Download & print
            </Themed.h2>
            <ul
              sx={{
                listStyleType: "none",
                padding: 0,
                display: "flex",
                flexDirection: ["column", "row"],
                flexWrap: "wrap",
                "> *": {
                  marginRight: [0, "1rem"],
                  marginBottom: "1rem",
                },
              }}
            >
              <li>
                <PrintButton />
              </li>
              {activity.wordDocument && (
                <li>
                  <DownloadWordButton url={activity.wordDocument} />
                </li>
              )}
              <li>
                <CopyURLButton url={location.href} />
              </li>
            </ul>
          </div>
        </div>
        {!isTablet && secondColumn}
      </div>

      <div sx={{ maxWidth: 680, "@media print": { display: "none" } }}>
        <div sx={{ mb: 64, "@media print": { display: "none" } }}>
          <Themed.h2 sx={{ variant: "text.h4", marginBottom: "30px" }}>
            Share this activity
          </Themed.h2>
          <ul
            sx={{
              listStyleType: "none",
              padding: 0,
              display: "flex",
              flexDirection: ["column", "row"],
              flexWrap: "wrap",
              "> *": {
                marginRight: [0, "1rem"],
                marginBottom: "1rem",
              },
            }}
          >
            <li>
              <EmailButton emailBody={location.href} />
            </li>
            <li>
              <FacebookButton url={location.href} />
            </li>
            <li>
              <PinterestButton
                description={activity.title}
                imageUrl={activity.cardImage.url}
                url={location.href}
              />
            </li>
          </ul>
        </div>
        <div
          sx={{
            mb: 64,
            pl: 23,
            borderLeft: "2px solid var(--theme-ui-colors-lbbRed)",
            "--theme-ui-colors-text": didLikePost
              ? "var(--theme-ui-colors-lbbRed)"
              : "inherit",
            "--theme-ui-colors-background": didLikePost ? "white" : "inherit",
            "@media print": { display: "none" },
          }}
        >
          <Themed.h2
            sx={{ variant: "text.h4", marginBottom: "30px" }}
            role={announceLike ? "alert" : undefined}
          >
            {didLikePost
              ? `you and ${likes} others like this activity`
              : `${
                  likes === 1 ? `${likes} person likes` : `${likes} people like`
                } this activity`}
          </Themed.h2>
          <Pill
            disabled={didLikePost}
            label="Like blog post"
            icon={{
              url: didLikePost ? likeIconRed : likeIconWhite,
              activeUrl: likeIcon,
              width: 18,
              height: 17,
            }}
            onClick={handleLikePost}
            style={{
              border: "none",
              boxShadow: "0 4px 10px 0 rgba(0,0,0,0.2)",
            }}
          />
        </div>
      </div>
      <div
        sx={{
          display: ["block", null, "flex"],
          justifyContent: "space-between",
        }}
      >
        <div sx={{ maxWidth: [null, null, 700] }}>
          <CommentsSection
            addComment={handleAddComment}
            comments={comments}
            container="activity"
            entryId={activity.id}
            title="Comments"
          />
        </div>

        {!isAuthenticated && (
          <section
            sx={{
              backgroundColor: "var(--theme-ui-colors-lbbYellow)",
              padding: "20px 36px",
              maxWidth: [null, null, 420],
              textAlign: "center",
              mb: "auto",
            }}
          >
            <Themed.h4>Login to post your comment!</Themed.h4>
            <Themed.p sx={{ mb: 4 }}>
              Login to your existing profile or create a new profile in order to
              leave a comment on this activity.
            </Themed.p>
            <div
              sx={{
                display: "flex",
                flexDirection: ["column", "row", "column"],
                gap: "1rem",
                justifyContent: "center",
                alignItems: "center",
                mb: 35,
              }}
            >
              <Pill
                label="Login or create a new user profile"
                onClick={goToLogin}
                type="button"
              ></Pill>
            </div>
          </section>
        )}
      </div>

      <div sx={{ "@media print": { display: "none" } }}>
        <Separator />
        <Themed.h2>{`${activity.activityGroup.level} activity groups`}</Themed.h2>
        <ActivityGroupCardGrid
          activityGroups={activityGroups.filter(
            group => group.level === activity.activityGroup.level
          )}
        />
        <Separator />
        <QuickActivityFinder
          activities={allActivities}
          activityGroups={activityGroups}
          prefilter={{ [activity.activityGroup.uid]: true }}
        />
        {lightboxImageIndex !== null && (
          <Lightbox
            images={activity.images}
            startIndex={lightboxImageIndex}
            onClickPrev={currentIndex => {
              setLightboxImageIndex(currentIndex - 1);
            }}
            onClickNext={currentIndex => {
              setLightboxImageIndex(currentIndex + 1);
            }}
            onClickClose={() => setLightboxImageIndex(null)}
          />
        )}
      </div>
    </Layout>
  );
};

export default Activity;
