import { useThemeUI } from "theme-ui";
import { useState, useCallback, useEffect } from "react";

const matchBreakpoint = breakpoint => {
  if (typeof window === "undefined") {
    return false;
  }

  /*
    The breakpoints from the theme are minimum width, so we actually want to
    match the breakpoint below the one passed to this function
  */
  const breakpointNumber = parseInt(breakpoint);
  const test = window.matchMedia(`(max-width: ${breakpointNumber - 1}px)`);

  return test.matches ? true : false;
};

const useWatchBreakpoint = breakpoint => {
  const [matchesBreakpoint, setMatchesBreakpoint] = useState(
    matchBreakpoint(breakpoint)
  );

  const onResize = useCallback(() => {
    setMatchesBreakpoint(matchBreakpoint(breakpoint));
  }, [breakpoint]);

  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, [onResize]);

  return matchesBreakpoint;
};

export const useIsMobile = () => {
  const {
    theme: { breakpoints },
  } = useThemeUI();
  const mobileBreakpoint = breakpoints[0];

  return useWatchBreakpoint(mobileBreakpoint);
};

// TODO this returns true for mobile width too, might want to change that
export const useIsTablet = () => {
  const {
    theme: { breakpoints },
  } = useThemeUI();
  const tabletBreakpoint = breakpoints[1];

  return useWatchBreakpoint(tabletBreakpoint);
};
